<template>
  <div style="height: 100vh" class="my-5">
    <div class="columns is-centered is-vcentered">
      <div class="column is-4-desktop is-6-tablet is-full-mobile">
        <div class="card">
          <div class="card-header" style="background: linear-gradient(89.31deg, #211895 1.38%, #E64BFF 97.6%);">
            <div class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
              <img width="150" :src="`/logos/${site}/logo.svg`" />
              <img width="150" :src="`/logos/${site}/credit-card.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="tab === 0">
            <p class="mb-4 p-3 has-text-centered	has-background-danger has-text-white">{{ errMsg }}</p>
            <div class="is-flex is-justify-content-end">
              <b-button type="is-danger" @click="windowClose" size="is-small">Kapat</b-button>
            </div>
          </div>
          <section class="section is-main-section" v-if="tab === 1">
            <card-component title="Yatırım Bilgileriniz">
              <form @submit.prevent="save">
                <b-field label="Ad & Soyad" message="Yatırımcının Ad & Soyad bilgisi">
                  <b-input v-model="form.name" size="is-small"/>
                </b-field>
                <b-field label="Kart Sahibi" message="Kredi kartınızdaki ad soyad ile aynı olmalıdır">
                  <b-input v-model="form.cardName" size="is-small"/>
                </b-field>
                <b-field label="Kart Numarası">
                  <b-input v-model="form.cardNumber" size="is-small"/>
                </b-field>
                <b-field grouped group-multiline>
                  <b-field label="Son kullanma tarihi" class="mb-3">
                    <b-select placeholder="Yıl" v-model="form.cardYear" size="is-small">
                      <option :value="item" :key="item" v-for="item in years">
                        {{ item }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label=".">
                    <b-select v-model="form.cardMonth" size="is-small" placeholder="Ay">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </b-select>
                  </b-field>
                  <b-field label="CVV Kodu">
                    <b-input v-model="form.cardCvv" size="is-small"/>
                  </b-field>
                </b-field>
                <b-field label="Yatırım tutarı" message="Minimum 50 TRY">
                  <b-input v-model="form.amount" size="is-small"/>
                </b-field>
                <div class="is-flex is-full is-justify-content-end">
                  <b-button class="mr-3" type="is-danger" size="is-small" @click="windowClose">Kapat</b-button>
                  <b-button :disabled="!isValid" type="is-success" size="is-small" native-type="submit">Devam et</b-button>
                </div>
              </form>
            </card-component>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {
  IntegrationService,
  TransactionService,
  UserService,
} from "@/services";

export default {
  name: "CardPayIframe",
  components: {CardComponent},
  data() {
    return {
      loading: false,
      error: false,
      tab: 0,
      errMsg: "",
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        userId: this.$route.query.userId,
        processId: this.$route.query.processId,
        ip: this.$route.query.ip || null,
        userPhone: this.$route.query.userPhone || null,
        cardNumber: this.$route.query.cardNumber || '',
        cardMonth: this.$route.query.cardMonth || new Date().getMonth() + 1,
        cardYear: this.$route.query.cardYear || new Date().getFullYear(),
        cardCvv: this.$route.query.cardCvv || '',
        cardName: this.$route.query.cardName || '',
        successRedirectUrl: process.env.VUE_APP_SITE_URL + "card/success",
        failRedirectUrl: process.env.VUE_APP_SITE_URL + "card/error",
        jwt: this.$route.query.jwt
      },
      years: [],
    };
  },
  computed: {
    isValid() {
      return (
          this.form.appKey &&
          this.form.appSecret &&
          this.form.userId &&
          this.form.username &&
          this.form.name &&
          this.form.amount >= 50 &&
          this.form.cardMonth &&
          this.form.cardYear &&
          this.form.cardName &&
          this.form.cardNumber.length === 16 &&
          this.form.cardCvv.length === 3
      );
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0];
    html.classList = [];
    this.init();
    const yearLimit = new Date().getFullYear() + 10;
    for (let i = new Date().getFullYear(); i < yearLimit; i++) {
      this.years.push(i);
    }
  },
  methods: {
    init() {
      UserService.providerCheck(this.form)
          .then(() => (this.tab = 1))
          .catch(() => ((this.error = true), (this.errMsg = "Geçersiz işlem")));
    },
    async save() {
      const name = this.form.name.split(" ").slice(0, -1).join(" ");
      const surname = this.form.name.split(" ").slice(-1)[0];
      const request = {
        username: this.form.username,
        name: name,
        surname: surname,
        amount: this.form.amount,
        userId: this.form.userId,
        ip: this.form.ip,
        processId: this.form.processId,
        userPhone: this.form.userPhone,
        cardNumber: this.form.cardNumber.toString(),
        cardYear: this.form.cardYear.toString(),
        cardMonth: this.form.cardMonth.toString(),
        cardCvv: this.form.cardCvv.toString(),
        cardName: this.form.cardName.toString(),
        successRedirectUrl: this.form.successRedirectUrl,
        failRedirectUrl: this.form.failRedirectUrl,
        jwt: this.form.jwt
      };
      const {data} = await TransactionService.hashCreate(request, this.form.appSecret);

      IntegrationService.creditCardDeposit(request, data, this.form.appKey)
          .then((res) => window.location.href = res.data.redirect)
          .catch((err) => {
            this.tab = 0;
            this.errMsg = err.message.Sonuc_Str || err.message;
          });
    },
  },
};
</script>
